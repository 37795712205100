import React from 'react';
import Typewriter from 'typewriter-effect';
import './cover.css';

export default function Cover() {
  return (
    <section>
      <div className="container">
        <div className="d-flex h-100-vh mx-auto flex-column">
          <div className="mb-auto"></div>
          <main role="main">
            <div className="row no-gutters">
              <div className="col-lg">
                <div className="hero-header" >
                <h1>Hello!</h1>
                <h1>I'm Tashi</h1>
                </div>
              </div>
              <div className="text-center col-lg">
                <h3 className="hero-subhead">Software Engineer</h3>
                <p className="lead">
                  My primary expertise lies in backend development, particularly
                  in JavaScript with Node.js, Express, and MongoDB. I have some
                  experience with frontend technologies such as React and Vue.
                  I love coding, solving problems, and building test-driven
                  software applications, and have a natural affinity towards
                  learning and exploring new technologies.
              </p>

                <div className="hero-social">
                  <a href="https://github.com/JSAssassin" target="_blank">
                    <span className="fab fa-github social-link"></span>
                  </a>
                  <a href="https://www.linkedin.com/in/tashidgyeltshen/" target="_blank">
                    <span className="fab fa-linkedin social-link"></span>
                  </a>
                  <a href="mailto:tashidyeltshen@hotmail.com">
                    <span className="far fa-envelope social-link"></span>
                  </a>
                </div>
              </div>
            </div>
          </main>
          <div className="mt-auto">
            <div className="down-angle text-center bounce-2">
              <a href="#skills" className="fas fa-angle-down" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
