import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import './styles.css';

var mountNode = document.getElementById('app');
ReactDOM.render(<App />, mountNode);
