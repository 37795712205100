import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import './navbar.css';

export default class Navbar extends Component {
  componentDidMount() {
    document.addEventListener('click', this.collapseNav);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.collapseNav);
  }
  collapseNav() {
    $('.navbar-collapse').collapse('hide');
  }

  render() {
    return (
      <nav className="navbar navbar-expand-md navbar-light bg-light sticky">
        <a className="navbar-brand" href="#">
          TASHI D. GYELTSHEN
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                href="#skills"
                className="nav-link link"
                onClick={this.collapseNav}
              >
                Skills
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link link"
                href="#projects"
                onClick={this.collapseNav}
              >
                Projects
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link link"
                href="#contact"
                onClick={this.collapseNav}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
