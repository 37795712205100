import React from 'react';
import './skills.css';

export default function skills() {
  return (
    <section id="skills" className="section-content">
      <div className="container d-flex mx-auto flex-column">
        <h2 className="header">What I do</h2>
        <p className="lead skill-lead">
          I've got a multidisciplinary background spanning multiple industries,
          ranging from Aeronautical Engineering, Mechanical 3D Modeling and Design
          to Fullstack Web Development.
        </p>
        <div className="skill-tiles row no-gutters">
          <SkillItem
            title="Fullstack Web Development"
            icon="fab skill-icon fa-node-js"
            description="Using tools including HTML/CSS/JavaScript. NodeJs, Express and MongoDB on
              the server with React and Vue on the client."
          />
          <SkillItem
            title="Mobile Web Development"
            icon="fas skill-icon fa-mobile-alt"
            description="Mobile responsive web design as well as application development
              with Expo and React Native."
          />
          <SkillItem
            title="3D CAD Modeling"
            icon="fas skill-icon fa-cube"
            description="Engineering 3D Modeling and Design using Solidworks and Catia V5. I also
              design for 3D printing."
          />
        </div>
      </div>
    </section>
  );
}

function SkillItem({ icon, title, description }) {
  return (
    <div className="flip-card col-3-lg">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <div className="front-content row no-gutters">
          <i className={icon + ' col-1'}></i>
          <h3 className="col">{title}</h3>
          </div>
        </div>
        <div className="flip-card-back">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}
