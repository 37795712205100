import React, { Component } from 'react';
import brtrImage from '../assets/brtr.png';
import objectsImage from '../assets/objects.png';
import SafeSlotImage from '../assets/safeslot.png';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './projects.css';

export default class Projects extends Component {
  render() {
    let settings = {
      dots: true,
      infinite: true,
      centerPadding: '200px',
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true
    };
    return (
      <section id="projects" className="section-content">
        <div className="container d-flex mx-auto flex-column">
          <h2 className="header">Projects</h2>
          <Slider {...settings}>
            <ProjectItem
              title="BettrBartr"
              description="BettrBartr is a web application built with Angular, Typescript, Bootstraps, Node.js,
          Express, Javascript, Sequelize and PostgreSQL. It aims to give users the ability to exchange
          items and services with their local community through an auction-based system."
              imageSrc={brtrImage}
              githubUrl="https://github.com/btrbrtrlilfoot/capstoneproject"
            />
             <ProjectItem
              title="SafeSlot"
              description="SafeSlot is an open source project that aims to help the community get access to store
              services safely during the Covid crisis. It is built with React, Reactstrap, Node.js, Express and MongoDB."
              imageSrc={SafeSlotImage}
              githubUrl="https://github.com/shubh28/safeslot-client"
            />
            <ProjectItem
              title="Finding Objects"
              description="A mobile scavenger hunt game that uses machine learning and object
          classification built during a three-day hackathon, using React Native and Expo for Cross-platform mobile support"
              imageSrc={objectsImage}
              githubUrl="https://github.com/JSAssassin/FindingObjects"
            />
          </Slider>
        </div>
      </section>
    );
  }
}

function ProjectItem(props) {

  return (
      <div className="project-item mx-2">
        <div className="row no-gutters">
          <div className="col-xl-6">
            <img src={props.imageSrc} target="_blank" className="project-image" />
          </div>
          <div className="col-xl-6">
            <h3 className="project-title">{props.title}</h3>
            <p>{props.description}</p>
            {props.githubUrl && (
              <a href={props.githubUrl} target="_blank" className="link link-btn">
                Source
              </a>
            )}
          </div>
        </div>
      </div>
    );
}
