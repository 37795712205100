import React from 'react';

export default function Bio() {
  return (
    <section id="about" className="section-content">
      <div className="d-flex  flex-column container">
        <h2 className="header">Fun Facts</h2>
        <p className="lead bio">
          Born and raised in the mystical mountains of Bhutan, currently
          living in the US. Outside of my coding life, I love cats and enjoy
          3D modeling and engineering CAD design, taking pictures, origami,
          gardening, music and playing guitar. I speak 5 different languages
          and consider myself a lifelong learner. I firmly believe that you can
          learn anything you put your mind to. There is no end to the journey
          of learning!
        </p>
        <div className="row no-gutters">
          <div className="bio-links">
            <a
              href="http://vsco.co/tashidgyeltshen"
              target="_blank"
              className="link link-btn"
            >
              My Gallery
            </a>
          </div>
          <div className="bio-links">
            <a
              href="https://drive.google.com/file/d/1FF8KKQCQKtOb55HAabfbhqC3kYNUufN2/view?usp=sharing"
              target="_blank"
              className="link link-btn"
            >
              3D Models
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
