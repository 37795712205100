import React from 'react';
import Cover from './cover';
import Footer from './footer';
import Skills from './skills';
import Navbar from './navbar';
import Contact from './contact';
import Projects from './projects';
import Bio from './bio';

export default function App() {
  return (
    <div>
      <Navbar />
      <Cover />
      <Skills />
      <Projects />
      <Bio />
      <Contact />
      <Footer />
    </div>
  );
}
